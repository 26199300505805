<template>
  <vx-card>
    <form-wizard
        color="rgba(var(--vs-primary), 1)"
        :title="'PROYECTOS'"
        :subtitle="'Siga los pasos para completar el proceso'"
        :hide-buttons="false"
    >
      <tab-content title="Datos Generales" class="mb-5" :beforeChange="validateGeneralData">
        <form data-vv-scope="general-data">
          <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Información general</vs-divider>
          <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full">
              <label for="name" class="w-full select-large">Nombre</label>
              <vs-input
                  id="name" name="name" v-model="entryModel.name" class="w-full" v-validate="'required'"
                  autocomplete="off"
              />
              <span class="text-danger">{{ errors.first("general-data.name") }}</span>
              <br/>
            </div>

            <div class="vx-col md:w-1/3 w-full">
              <label for="title" class="w-full select-large">Título</label>
              <vs-input
                  id="title" name="title" v-model="entryModel.title" class="w-full" v-validate="'required'"
                  autocomplete="off"
              />
              <span class="text-danger">{{ errors.first("general-data.title") }}</span>
              <br/>
            </div>

            <div class="vx-col md:w-1/3 w-full">
              <label for="construction_company" class="w-full select-large">Desarrollador</label>
              <vs-input
                  id="construction_company" name="construction_company" v-model="entryModel.construction_company"
                  class="w-full" v-validate="'required'" autocomplete="off"
              />
              <span class="text-danger">{{ errors.first("general-data.construction_company") }}</span>
              <br/>
            </div>
          </div>

          <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Información de proyecto</vs-divider>
          <div class="vx-row">
            <div class="vx-col w-full">
              <label class="w-full select-large">Descripción</label>
              <editor
                  id="project-description"
                  :content.sync="entryModel.description.html"
                  :resourceType="resource"
              />
              <br/>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full">
              <label for="base_price" class="w-full select-large">Precio base</label>
              <vs-input
                  id="base_price" name="base_price" v-model="entryModel.base_price"
                  class="w-full" v-validate="'required|decimal:2'" autocomplete="off"
              />
              <span class="text-danger">{{ errors.first("general-data.base_price") }}</span>
              <br/>
            </div>

            <div class="vx-col md:w-1/2 w-full">
              <label for="currency" class="w-full select-large">Moneda</label>
              <vs-select
                  id="currency" v-model="entryModel.currency" class="w-full select-large" v-validate="'required'"
              >
                <vs-select-item key="usd" value="USD" :text="'USD'" class="w-full"/>
                <vs-select-item key="hnl" value="HNL" :text="'HNL'" class="w-full"/>
              </vs-select>
              <br/>
            </div>
          </div>

          <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Ubicación</vs-divider>
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full">
              <label for="state" class="w-full select-large">Departamento</label>
              <v-select
                  id="state"
                  name="state"
                  label="name"
                  index="id"
                  :options="states"
                  :placeholder="'Seleccione..'"
                  :searchable="true"
                  v-model="entryModel.state"
                  v-validate="'required'"
                  @input="getCities(entryModel.state)"
                  class="w-full"
              />
              <span class="text-danger">{{ errors.first("general-data.state") }}</span>
              <br/>
            </div>

            <div class="vx-col md:w-1/2 w-full">
              <label for="city" class="w-full select-large">Ciudad</label>
              <v-select
                  id="city"
                  name="city"
                  label="name"
                  index="id"
                  :options="cities"
                  :placeholder="'Seleccione..'"
                  :searchable="true"
                  v-model="entryModel.city"
                  v-validate="'required'"
                  class="w-full"
              />
              <span class="text-danger">{{ errors.first("general-data.city") }}</span>
              <br/>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full">
              <label for="lat" class="w-full select-large">Latitud</label>
              <vs-input
                  id="lat" name="lat" v-model="entryModel.geolocation.lat" v-validate="'required'"
                  class="w-full" autocomplete="off"
              />
              <span class="text-danger">{{ errors.first("general-data.lat") }}</span>
              <br/>
            </div>

            <div class="vx-col md:w-1/2 w-full">
              <label for="lng" class="w-full select-large">Longitud</label>
              <vs-input
                  id="lng" name="lng" v-model="entryModel.geolocation.lng" v-validate="'required'"
                  class="w-full" autocomplete="off"
              />
              <span class="text-danger">{{ errors.first("general-data.lng") }}</span>
              <br/>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full">
              <label for="address" class="w-full select-large">Dirección</label>
              <vs-textarea id="address" name="address" v-model="entryModel.address" v-validate="'required'" rows="3"/>
              <span class="text-danger">{{ errors.first("general-data.address") }}</span>
              <br/>
            </div>
          </div>

          <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Publicación</vs-divider>
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full mt-5">
              <label for="publish_at" class="w-full select-large">Fecha y hora de publicación</label>
              <flat-pickr
                  :config="configDateTimePicker"
                  id="publish_at"
                  v-model="entryModel.publish_at"
                  class="w-full flatpickr flatpickr-input"
              />
              <br/>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-5">
              <label for="expire_at" class="w-full select-large">Fecha y hora de expiración</label>
              <flat-pickr
                  :config="configDateTimePicker"
                  id="expire_at"
                  v-model="entryModel.expire_at"
                  class="w-full flatpickr flatpickr-input"
              />
              <br/>
            </div>
          </div>
        </form>
      </tab-content>

      <!-- tab 2 content -->
      <tab-content title="Opciones Disponibles" class="mb-5">
        <vs-row vs-justify="left">
          <vs-col v-for="(option, index) in entryModel.available_options" type="flex" vs-justify="center" vs-align="center" vs-w="12">
            <vs-card class="pt-6 pl-6 pr-6">
              <div slot="header">
                <h3 v-if="option.type === 'house'">
                  <vs-icon icon="house"></vs-icon> Casa: {{ option.name }}
                </h3>
                <h3 v-else-if="option.type === 'apartment'">
                  <vs-icon icon="apartment"></vs-icon> Apartamento
                </h3>
                <h3 v-else-if="option.type === 'terrain'">
                  <vs-icon icon="place"></vs-icon> Terreno
                </h3>
              </div>
              <div>
                <div class="vx-row">
                  <div class="vx-col md:w-1/2 w-full">
                    <label :for="'type-' + index" class="w-full select-large">Tipo</label>
                    <vs-select
                        :id="'type-' + index" v-model="option.type" class="w-full select-large" v-validate="'required'"
                    >
                      <vs-select-item key="house" value="house" :text="'Casa'" class="w-full"/>
                      <vs-select-item key="apartment" value="apartment" :text="'Apartamento'" class="w-full"/>
                      <vs-select-item key="terrain" value="terrain" :text="'Terreno'" class="w-full"/>
                    </vs-select>
                    <br/>
                  </div>
                  <div class="vx-col md:w-1/2 w-full">
                    <label :for="'name-' + index" class="w-full select-large">Nombre</label>
                    <vs-input
                        :id="'name-' + index" v-model="option.name" class="w-full" v-validate="'required'"
                        autocomplete="off"
                    />
                    <br/>
                  </div>
                </div>
                <div v-if="['house', 'apartment', 'terrain'].includes(option.type)" class="vx-row">
                  <div class="vx-col md:w-1/2 w-full">
                    <label :for="'description-' + index" class="w-full select-large">Descripción</label>
                    <editor
                        :id="'description-' + index"
                        :content.sync="option.description.html"
                        :resourceType="resource"
                    />
                    <br/>
                  </div>
                  <div class="vx-col md:w-1/2 w-full">
                    <label :for="'price-' + index" class="w-full select-large">Precio</label>
                    <editor
                        :id="'price-' + index"
                        :content.sync="option.price.html"
                        :resourceType="resource"
                    />
                    <br/>
                  </div>
                </div>
                <div class="vx-row">
                  <div v-if="['house', 'terrain'].includes(option.type)" class="vx-col md:w-1/2 w-full">
                    <label :for="'land-area-' + index" class="w-full select-large">Área de terreno</label>
                    <editor
                        :id="'land-area-' + index"
                        :content.sync="option.land_area.html"
                        :resourceType="resource"
                    />
                    <br/>
                  </div>
                  <div v-if="['house', 'apartment'].includes(option.type)" class="vx-col md:w-1/2 w-full">
                    <label :for="'construction-area-' + index" class="w-full select-large">Área de construcción</label>
                    <editor
                        :id="'construction-area-' + index"
                        :content.sync="option.construction_area.html"
                        :resourceType="resource"
                    />
                    <br/>
                  </div>
                </div>
                <vs-row vs-justify="flex-end">
                  <vs-button
                      :id="'delete-' + index" radius
                      color="primary" type="filled" icon-pack="feather" icon="icon-trash-2"
                      @click="deleteOption(index)"
                  ></vs-button>
                </vs-row>
              </div>
            </vs-card>
          </vs-col>
        </vs-row>

        <vs-row vs-align="center" vs-justify="center">
          <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">
            <vs-button
                color="primary" type="filled" icon-pack="feather" icon="icon-plus" style="width: 100%;"
                @click="addOption()"
            >
              Agregar Nueva Opción
            </vs-button>
          </vs-col>
        </vs-row>
      </tab-content>

      <!-- tab 3 content -->
      <tab-content title="Recursos" class="mb-5">
        <label class="w-full select-large">Inserte la imagen que se verá cuando se comparta un link de la página</label>
        <vs-divider class="vx-col w-full"/>

        <div class="vx-row">
          <div class="vx-col w-full mb-5">
            <uploader
                :classFile="'file-upload-1'"
                :maxNumberOfFiles="1"
                @files-removed="removedFiles('open_graph')"
                @files-updated="openGraphUpload"
                :downloadedFiles="entryModel.media.open_graph"
            />
          </div>
        </div>

        <label class="w-full select-large">Inserte el thumbnail de la página</label>
        <vs-divider class="vx-col w-full"/>

        <div class="vx-row">
          <div class="vx-col w-full mb-5">
            <uploader
                :classFile="'file-upload-2'"
                :maxNumberOfFiles="1"
                @files-removed="removedFiles('thumbnail')"
                @files-updated="thumbnailUpload"
                :downloadedFiles="entryModel.media.thumbnail"
            />
          </div>
        </div>

        <label class="w-full select-large">Inserte las imágenes para mostrar</label>
        <vs-divider class="vx-col w-full"/>

        <div class="vx-row">
          <div class="vx-col w-full mb-5">
            <uploader
                :classFile="'file-upload-3'"
                @files-removed="removeCarouselFiles($event)"
                @files-updated="setImages"
                :downloadedFiles="entryModel.media.carousel"
            />
          </div>
        </div>
      </tab-content>

      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left">
          <vs-button
              v-if="props.activeTabIndex > 0"
              class="wizard-footer-left finish-button"
              @click.native="props.prevTab()"
          >
            Anterior
          </vs-button>
        </div>

        <div class="wizard-footer-right">
          <vs-button
              v-if="!props.isLastStep"
              class="wizard-footer-right finish-button"
              @click.native="props.nextTab()"
          >
            Siguiente
          </vs-button>
          <vs-button
              v-else
              class="wizard-footer-right finish-button"
              @click.native="props.nextTab()"
              @click="createOrUpdateEntry"
          >Enviar
          </vs-button>
          <vs-button
              color="danger"
              type="border"
              class="wizard-footer-right finish-button mr-3"
              @click="goToEntriesList"
          >Cancelar
          </vs-button>
        </div>
      </template>
    </form-wizard>
  </vx-card>
</template>

<script>
import CoreClient from "../../utils/coreClient";
import EntriesClient from "../../utils/entriesClient";
import RealStateProjectsClient from "../../utils/realStateProjectsClient";
import Notifier from "./../../utils/notification";
import Configurations from "./../../utils/configurations";
import Upload from "./../../utils/upload";
import {FormWizard, TabContent} from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import vSelect from "vue-select";
import Editor from "./../../components/Editor.vue";
import Builder from "./../../components/Builder.vue";
import Uploader from "./../../components/Uploader.vue";
import Input from "@/views/forms/form-elements/input/Input";

let notifier = new Notifier();
let configurations = new Configurations();
const ENTRY_CLIENT = new RealStateProjectsClient();
const STATES_CLIENT = new CoreClient("states");
const CITIES_CLIENT = new EntriesClient("cities");
const UPLOAD = new Upload("resource");

export default {
  data() {
    return {
      configDateTimePicker: configurations.configDatePicker(),
      resource: "projects",
      object: "",
      files: [],
      carouselCopy: [],
      carouselUpload: [],
      states: [],
      cities: [],
      entryModel: {
        state: null,
        city: null,
        construction_company: null,
        name: null,
        title: null,
        address: null,
        description: {
          html: null
        },
        base_price: null,
        currency: null,
        geolocation: {
          lat: null,
          lng: null,
        },
        media: {
          open_graph: {
            url: null,
            meta: {
              title: null,
              description: null
            }
          },
          thumbnail: {
            url: null,
            meta: {
              title: null,
              description: null
            }
          },
          carousel: [],
        },
        available_options: [],
        publish_at: null,
        expire_at: null
      }
    };
  },

  mounted() {
    if (this.$route.params.id !== undefined) {
      this.$vs.loading();
      this.loadEntryData(this.$route.params.id)
          .then(() => {
            this.$vs.loading.close();
          });
    } else {
      this.$vs.loading();
      this.getStates(false)
          .then(() => {
            this.$vs.loading.close();
          });
    }
  },

  methods: {
    async getStates(showLoading = true) {
      if (showLoading) this.$vs.loading();

      await STATES_CLIENT.all()
          .then(response => {
            if (showLoading) this.$vs.loading.close();
            this.states = response.data;
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
    },

    async getCities(state, showLoading = true) {
      if (state !== null && state !== undefined && state !== "") {
        if (showLoading) this.$vs.loading();

        await CITIES_CLIENT.all({queryStrings: {state_id: state.id}})
            .then(response => {
              this.cities = response.data;
              if (showLoading) this.$vs.loading.close();
            })
            .catch(error => {
              if (showLoading) this.$vs.loading.close();
              notifier.notification("error");
            });
      } else {
        this.cities = [];
        this.entryModel.city = null;
      }
    },

    async loadEntryData(entryId) {
      await ENTRY_CLIENT.retrieve({pathParameters: {id: entryId}})
          .then(response => {
            this.getStates(false)
                .then(() => {
                  if (response.data.city !== undefined && response.data.city.id !== null) {
                    this.getCities(response.data.state, false)
                        .then(() => {
                          Object.assign(this.entryModel, response.data);
                        });
                  }
                });
          })
          .catch(error => {
            notifier.notification("error");
          });
    },

    async createOrUpdateEntry() {
      this.$vs.loading();
      await this.uploadImages();
      this.entryModel.media.carousel = this.carouselUpload;

      let sendData = {};
      this.cleanModel();
      Object.assign(sendData, this.entryModel);

      if (this.entryModel.id !== null && this.entryModel.id !== undefined && this.entryModel.id !== "") {
        await ENTRY_CLIENT.update({pathParameters: {id: this.entryModel.id}, data: sendData})
            .then(response => {
              this.$vs.loading.close();
              notifier.notification("updated");
              this.goToEntriesList();
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
      } else {
        await ENTRY_CLIENT.create({data: sendData})
            .then(response => {
              this.$vs.loading.close();
              notifier.notification("created");
              this.goToEntriesList();
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
      }
    },

    setImages(files) {
      this.files = files;
    },

    async uploadFile(files, index) {
      let carousel = this.entryModel.media.carousel;

      if (this.carouselCopy.length > 0) {
        carousel = [];
      }

      if (files !== undefined && files !== null && files !== "") {
        this.$vs.loading();
        await UPLOAD.uploadFiles({
          queryStrings: {resource_type: this.resource, content_type: files.type},
          files: files,
          multiple: true
        })
            .then(response => {
              let slide = {
                url: response,
                meta: {
                  title: files.meta.name,
                  description: files.meta.caption
                }
              };
              this.carouselUpload.push(slide);
            })
            .catch(error => {
              notifier.notification("error");
            });
        this.$vs.loading.close();
      }
    },

    async openGraphUpload(files) {
      if (files.length > 0 && files[0].source === "Dashboard") {
        this.$vs.loading();
        await UPLOAD.uploadFiles({
          queryStrings: {resource_type: this.resource, content_type: files[0].type},
          files: files
        })
            .then(response => {
              this.entryModel.media.open_graph.url = response;
              this.entryModel.media.open_graph.meta.title = files[0].meta.name;
              this.entryModel.media.open_graph.meta.description = files[0].meta.caption;
            })
            .catch(error => {
              notifier.notification("error");
            });
        this.$vs.loading.close();
      }
    },

    async thumbnailUpload(files) {
      if (files.length > 0 && files[0].source === "Dashboard") {
        this.$vs.loading();
        await UPLOAD.uploadFiles({
          queryStrings: {resource_type: this.resource, content_type: files[0].type},
          files: files
        })
            .then(response => {
              this.entryModel.media.thumbnail.url = response;
              this.entryModel.media.thumbnail.meta.title = files[0].meta.name;
              this.entryModel.media.thumbnail.meta.description = files[0].meta.caption;
            })
            .catch(error => {
              notifier.notification("error");
            });
        this.$vs.loading.close();
      }
    },

    async uploadImages() {
      if (this.files) {
        await Promise.all(
            this.files.map((curr, index) => this.uploadFile(curr, index))
        );
      }
    },

    removeCarouselFiles(data) {
      this.files.forEach((item, index, object) => {
        if (item.id === data.id) {
          object.splice(index, 1);
        }
      });
    },

    async removedFiles(files) {
      let mediaType = this.entryModel.media;
      let body = mediaType[files].url;

      body = JSON.stringify({url: body});

      await UPLOAD.removeFiles({data: body}).then(response => {
        mediaType[files] = {
          url: null,
          meta: {
            title: null,
            description: null
          }
        }
      }).catch(error => {
        notifier.notification("error");
      });
    },

    goToEntriesList() {
      this.$router.push({name: "real-state-projects"});
    },

    cleanModel() {
      Object.keys(this.entryModel).forEach(key => {
        if (this.entryModel[key] === null || this.entryModel[key] === "") {
          delete this.entryModel[key];
        }
      })
    },

    validateGeneralData() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll("general-data").then(result => {
          if (result) {
            resolve(true);
          } else {
            reject("Please fix invalid fields");
          }
        })
      });
    },

    deleteOption(index){
      this.entryModel.available_options.splice(index, 1);
    },

    addOption(){
      this.entryModel.available_options.push({
        name: "",
        type: "house",
        price: {
          html: ""
        },
        description: {
          html: ""
        },
        construction_area: {
          html: ""
        },
        land_area: {
          html: ""
        },
      });
    }
  },

  components: {
    Input,
    Editor,
    FormWizard,
    TabContent,
    flatPickr,
    Builder,
    Uploader,
    "v-select": vSelect
  }
};
</script>